<template>
    <!-- 联系我们 -->
    <div class="Contact">
        <div class="header">
            <Head :add="routeAdd"></Head>
        </div>
        <div class="main">
            <div class="card">
                <div class="item">
                    <div class="img_box">
                        <img src="../assets/images/phone.png" />
                    </div>
                    <div class="text_box tBox_1">
                        <p class="title">PHONE</p>
                        <p class="text">400-0933-668</p>
                    </div>
                </div>
                <div class="item">
                    <div class="img_box">
                        <img src="../assets/images/loaction.png" />
                    </div>
                    <div class="text_box tBox_1">
                        <p class="title">ADDRESS</p>
                        <p class="text">重庆市渝北区食品大道</p>
                        <p class="text">重庆广告产业园13栋 D3</p>
                    </div>
                </div>
                <div class="item">
                    <div class="img_box other">
                        <img src="../assets/images/wechat.png" />
                    </div>
                    <div class="text_box tBox_3">
                        <div>
                            <img src="../assets/images/icon_wechat.png" />
                        </div>
                        <div>
                            <img src="../assets/images/icon_service.png" />
                        </div>
                        <div>
                            <img src="../assets/images/icon_business.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="map">
                <Map></Map>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../components/Head.vue";
import Map from '../components/Map.vue'
export default {
    components: {
        Head,
        Map,
    },
    data() {
        return {
            routeAdd: "",
        };
    },
    created() {
        this.routeAdd = this.$route.name;
    },
};
</script>

<style lang="scss" scoped>
.Contact {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: url("../assets/images/contact_background.png") no-repeat;
    .header {
        height: 6.25rem;
    }
    .main {
        position: relative;
        .card {
            position: absolute;
            top: 10.5rem;
            left: 18.63rem;
            width: 33.13rem;
            height: 506px;
            .item {
                display: flex;
                height: 10.25rem;
                background-color: #fff;
                border-radius: 2px;
                color: #333;
            }
            .item:nth-of-type(2) {
                margin: 7px 0;
            }
            .img_box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12.38rem;
                background-color: #eee;
            }
            .other {
                background-color: rgba(238, 238, 238, 0.25);
            }
            .text_box {
                flex: 1;
                font-family: MicrosoftYaHei-Bold;
                .title {
                    font-size: 17px;
                    margin-bottom: 10px;
                }
                .text {
                    font-size: 20px;
                }
            }
            .tBox_1 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 2.13rem;
            }
            .tBox_3 {
                display: flex;
                justify-content: center;
                align-items: center;
                div:nth-of-type(2) {
                    margin: 0 2.3rem;
                }
            }
        }
        .map {
            position: absolute;
            top: 10.5rem;
            left: 52.38rem;
            width: 49.19rem;
            height: 31.63rem;
            background-color: #fdfcfa;
            border-radius: 0.13rem;
        }
    }
}
</style>
