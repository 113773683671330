<template>
    <div class="container">
        <div class="logo">
            <img src="../assets/images/logo.png" />
        </div>
        <div class="nav">
            <template v-for="(item,index) in title">
                <div class="link" :key="index" @click="to">
                    <div class="text">{{item.label}}</div>
                    <div class="bar" v-if="add==item.value?true:false"></div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: [
                { value: "Home", label: "首页" },
                { value: "Enter", label: "走进城银" },
                { value: "Group", label: "集团产业" },
                { value: "Duty", label: "社会责任" },
                { value: "Contact", label: "联系我们" },
            ],
        };
    },
    props: {
        add: String,
    },
    methods: {
        to(e) {
            switch (e.target.outerText) {
                case "首页":
                    this.$router.push("/");
                    break;
                case "走进城银":
                    this.$router.push("/Enter");
                    break;
                case "集团产业":
                    this.$router.push("/Group");
                    break;
                case "社会责任":
                    this.$router.push("/Duty");
                    break;
                case "联系我们":
                    this.$router.push("/Contact");
                    break;
                default:
                    this.$router.push("/404");
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    border-bottom: 2px solid rgba(151, 186, 218, 0.25);
    .logo {
        display: flex;
        align-items: center;
        img {
            margin-left: 22.5rem;
        }
    }
    .nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 34.44rem;
        .link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 4.5rem;
            height: 6.25rem;
            margin-right: 2.25rem;
            font-size: 18px;
            color: #fff;
            user-select: none;
            .text {
                flex: 1;
                text-align: center;
                line-height: 90px;
            }
            .bar {
                width: 100%;
                height: 0.5rem;
                background: url("../assets/images/bottom_bar.png") 8px 0px;
            }
        }
    }
}
</style>